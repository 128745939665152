/* DASHBOARD */

.dashboard {
  width: 50%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dashboard form {
  display: flex;
  flex-direction: column;
}

.dashboard input[type="text"],
.dashboard input[type="number"] {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.subject-row {
  display: flex;
  align-items: center;
}

.subject-row input {
  flex: 1;
  margin-right: 10px;
}

.subject-row button {
  padding: 10px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.dashboard button[type="button"],
.dashboard button[type="submit"] {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.result {
  margin-top: 20px;
  padding: 20px;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  border-radius: 5px;
}

.error {
  margin-top: 20px;
  padding: 20px;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  border-radius: 5px;
  color: #a94442;
}




/* S I G N  U P   /    L O G I N  */

.auth-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.auth-form {
  display: flex;
  flex-direction: column;
}

.auth-form input[type="text"],
.auth-form input[type="password"] {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.auth-form button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.auth-form button:hover {
  background-color: #45a049;
}

.auth-container p {
  margin-top: 20px;
}

.auth-container p a {
  color: #4CAF50;
  text-decoration: none;
}

.auth-container p a:hover {
  text-decoration: underline;
}



/* HOMEPAGE */

.home-content {
  padding: 20px;
  text-align: center;
  color: white;
}

.about-section {
  background-color: #ffffffcc; /* Semi-transparent white background for readability */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  margin: 20px auto;
}

.about-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.about-section p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #333;
}




/* src/components/AboutUs.css */

.about-us-container {
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
  background-color: #ffffffcc; /* Semi-transparent white background for readability */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #333;
}

.about-us-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-us-container p {
  font-size: 1.2rem;
  line-height: 1.6;
}
